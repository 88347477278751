import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header.js';
import Inicio from './pages/inicio.js';
import Nosotros from './pages/quienes-somos.js'
import Contactanos from './pages/contactanos.js';

function App() {
  return (
   <Router>
    <Header/>
    <Routes>
      <Route exact path="/" element={<Inicio/>} />
      <Route exact path="/contactanos" element={<Contactanos/>} />
      <Route exact path="/quienes-somos" element={<Nosotros/>} />
    </Routes>
   </Router>
  );
}

export default App;
import React from "react";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import jugadores from '../imgs/FONDOoptimizado.jpg'
import rebano from '../imgs/REBANNOOLIVOS.png'
import frase from '../imgs/FRASE.png'
const Inicio = () => {

    return(
        <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <Typography component="div" sx={{ flexGrow: 0 }}>
          <img src={jugadores} alt="img-jugadores" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Typography>
      </Grid>
      <div style={{display:'grid', gridTemplateColumns:'50% 50%', maxHeight: '130px'}}>
        <Typography component="div" sx={{ flexGrow: 0,  display: 'flex', position: 'relative', bottom: '55%', left:'10%' }}>
          {/* Segunda imagen */}
          <img src={rebano} alt="rebano" style={{ maxWidth: '100%' }} />
        </Typography>

        <Typography component="div" sx={{ flexGrow: 0}}>
          {/* Segunda imagen */}
          <img src={frase} alt="frase" style={{ maxHeight: '19%', display: 'flex',  position: 'relative', left: '20%', top: '20%' }} />
        </Typography>
      </div>
    </Grid>
    )
}

export default Inicio;
import React from "react";
import header from "../components/header.js"

const Nosotros = () => {

    return(
        <>
        <header></header>
        </>
    )
}

export default Nosotros
import React from "react";
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from "../../src/imgs/rebano.png";
import {Link} from  'react-router-dom';



const contactanos = () => {
    const iconStyle = {
        color: '#03588C', // Establece el color específico
      };
      const phoneNumber = '5575058091'
      const messageInfo = 'Hola, quiero información sobre la escuela Chivas Olivos'

      const handleWhatsAppClick = () => {
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(messageInfo)}`;
        console.log(whatsappLink)
        window.open(whatsappLink, '_blank');
      };
    
    return(
       <div id="contactanos" style={{textAlign:'center', display: 'flex', flexDirection: 'column', gap:'10%'}}>
         <Typography className='logo'  component="div" style={{marginBottom: '1.5%'}}>
          <img src={Logo} alt="Logo" style={{ maxWidth: '20%', height: '20%' }} />
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginBottom: '.8%' }}>
          <Typography variant="body1" sx={{ color: 'red' }}>
            HORARIOS DE ENTRENAMIENTO
          </Typography>
          <Typography variant="body1" sx={{ color: '#03588C' }}>
            Matutino: 8:00 A 10:00 AM
          </Typography>
          <Typography variant="body1" sx={{ color: '#03588C' }}>
            Vespertino: 17:00 A 19:00 PM
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginBottom: '1.5%' }}>
          <Typography variant="body1" sx={{ color: 'red' }}>
            DIRECCIÓN
          </Typography>
          <Typography variant="body1" sx={{ color: '#03588C' }}>
            Calle Gitana 258, Colonia del mar, CP 13270, Alcaldia Tlahuac
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginBottom: '1.5%' }}>
          <Typography variant="body1" sx={{ color: 'red' }}>
           CONTACTO
          </Typography>
        </div>
        <div   style={{display: 'flex', justifyContent: 'center'}} >
        <Typography className='' component="div" sx={{ flexGrow: 0 }} style={{gap: '20%', display: 'grid', gridTemplateColumns: 'auto auto auto', width: '30%', justifyContent: 'center'}}>
            <Link  onClick={() =>{handleWhatsAppClick()}}> <WhatsAppIcon style={iconStyle} fontSize="large" /> </Link>
        
     
       <Link target='blank' to='https://www.facebook.com/profile.php?id=100083448276590'> <FacebookIcon style={iconStyle} fontSize="large" /> </Link>
        
        
        <Link target="blank" to='https://www.instagram.com/chivas__olivos/'> <InstagramIcon style={iconStyle} fontSize="large" /> </Link>
        </Typography>
        </div>
       </div>
    )
}

export default contactanos
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Logo from '../imgs/rebano.png';
import {Link} from  'react-router-dom';

const NavMenu = () => {
  return (
<AppBar position="static" sx={{ background: 'white' }}>
      <Toolbar>
        <Typography className='logo' variant="h6" component="div" sx={{ flexGrow: 0 }}>
          <img src={Logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100px' }} />
        </Typography>

        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
          <Typography variant="body1" sx={{ color: 'red' }}>
            CENTRO FORMATIVO
          </Typography>
          <Typography variant="body1" sx={{ color: 'red' }}>
            REBAÑO OLIVOS A.C.
          </Typography>
        </div>

        <div style={{ marginLeft: 'auto' }}>
          <Link to='/'>
          <Button color="inherit" sx={{ color: 'red' }}>Inicio</Button>
          </Link>
          <Link to='/quienes-somos'>
          <Button color="inherit" sx={{ color: 'red' }}>Quiénes somos</Button>
          </Link>
          <Link to='/contactanos'>
          <Button color="inherit" sx={{ color: 'red' }}>Contáctanos</Button>
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavMenu;
